import React from 'react'
import Head from 'next/head'
import FormLayout from 'src/components/layout/formLayout/FormLayout'
import { PageWithLayout } from 'src/types/ui'
import { LoginForm } from 'src/features/auth/components/forms'
import { loginFormStore } from 'src/features/auth/state/loginFormStore'
import { userStore } from 'src/features/auth/state/userStore'
import { useRouter } from 'next/router'
const Page: PageWithLayout = () => {
    const router = useRouter()
    React.useEffect(() => {
        loginFormStore.setState({ returnPath: '/' })
        userStore.getState().user && router.push('/account')
    }, [])

    return (
        <>
            <Head>
                <title>Log In | BreweryDB</title>
            </Head>
            <FormLayout
                title='Log In'
                subtitle='Welcome back! Please log in to access your account.'
                columns={2}
            >
                <LoginForm />
            </FormLayout>
        </>
    )
}

Page.getLayout = function getLayout(page: React.ReactElement) {
    return <>{page}</>
}

export default Page
