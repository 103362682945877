import React from 'react'
import * as UI from '@brewlogix/blx-ui'
import Link from 'next/link'
import classNames from 'classnames'
import Image from 'next/image'
import LoginSplash from 'src/features/auth/components/LoginSplash'

interface FormLayoutProps {
    title: React.ReactNode
    subtitle: string
    children: React.ReactNode
    columns?: 1 | 2
}

const FormLayout: React.FC<FormLayoutProps> = ({ title, subtitle, children, columns = 1 }) => {
    return (
        <div>
            <div
                className={classNames(
                    'relative flex h-screen  min-h-[840px] items-center',
                    columns === 2
                        ? 'flex-row justify-center p-0 lg:p-6'
                        : 'flex-col justify-start gap-6 pt-8 md:justify-center md:gap-10 md:pt-0'
                )}
            >
                <div
                    className={classNames(
                        columns === 2 && 'fixed left-4 top-3 z-50 md:left-10 md:top-8'
                    )}
                >
                    <Link href='/'>
                        <a
                            className={classNames(
                                columns === 2
                                    ? 'flex min-w-32 max-w-[10rem] flex-col items-start justify-center px-0 md:w-64 md:min-w-48 md:pl-2'
                                    : 'flex w-full flex-col items-start justify-center px-0'
                            )}
                        >
                            <span className={'sr-only'}>BreweryDB</span>
                            <img
                                src='/brewerydb-logo--no-tagline.svg'
                                className='h-7 w-full min-w-36 md:h-12 md:min-w-56'
                                alt='BreweryDB logo'
                            />
                        </a>
                    </Link>
                </div>

                <div
                    className={classNames(
                        columns === 2
                            ? 'min-w-xl relative z-40 flex h-full w-full flex-col items-center justify-center overflow-auto bg-white px-4 scrollbar-hide lg:w-1/2 lg:px-0'
                            : 'flex w-full max-w-sm flex-col gap-y-4 rounded-lg border-px border-gray-100 p-8 lg:max-w-md lg:p-10'
                    )}
                >
                    <div className='flex w-full max-w-sm flex-col gap-y-4'>
                        {title && (
                            <UI.Heading customClasses='text-3xl font-extrabold mb-0' type='h1'>
                                {title}
                            </UI.Heading>
                        )}
                        {subtitle && (
                            <p className='text-md mb-2 font-semibold text-gray-300'>{subtitle}</p>
                        )}
                        {children}
                    </div>
                    <div
                        className={classNames(
                            'absolute bottom-0 left-0 w-full text-center',
                            columns === 1 ? 'pb-6' : 'pb-2'
                        )}
                    >
                        By logging in, you agree to our{' '}
                        <Link href='https://www.brewlogix.com/privacy-policy/'>
                            <a className='underline'>Privacy Policy</a>
                        </Link>
                        .
                    </div>
                </div>

                {columns === 2 && (
                    <LoginSplash
                        title='Discover New Breweries'
                        subtitle={`Search and filter what's important to you with Breweries For You`}
                        media={
                            <div className='relative -mr-6 aspect-[42/50] w-full max-w-sm lg:max-w-lg'>
                                <Image
                                    src='/assets/location-card-preview-01.png'
                                    layout='fill'
                                    alt='Preview of location card'
                                />
                            </div>
                        }
                    />
                )}
            </div>
        </div>
    )
}

export default FormLayout
