import Image from 'next/image'
import React from 'react'

interface LoginSplashProps {
    title?: string
    subtitle?: string
    media?: React.ReactNode
}

const LoginSplash: React.FC<LoginSplashProps> = ({ title, subtitle, media }) => {
    return (
        <div className='relative mx-auto hidden h-[calc(100vh-3rem)] min-h-96 w-1/2 flex-col items-center justify-center overflow-hidden rounded bg-green-800 lg:flex'>
            <div
                className='absolute right-[-72rem] top-[-115%] h-[120rem]  w-[120rem] rounded-full bg-green-600 shadow-xl'
                style={{
                    transform: 'matrix(0.59, 0.64, -1.15, 0.43, 0, 0)',
                }}
            ></div>

            <div className='absolute right-[-70rem] top-[-160%]  h-[126rem] w-[126rem] rotate-[-30deg] rounded-full bg-green-500 shadow-xl'></div>

            <div className='absolute bottom-0 left-0 right-0 top-0 flex h-full w-full bg-gradient-to-tr from-green-500 via-transparent to-green-800 bg-cover bg-center bg-no-repeat'></div>

            <div className='absolute bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col items-center justify-center text-center'>
                {media}
                <div className='flex flex-col items-center justify-center gap-4 px-4 text-white'>
                    <div className='text-2xl font-bold'>{title}</div>
                    <div className='text-lg'>{subtitle}</div>
                </div>
            </div>
        </div>
    )
}

export default LoginSplash
